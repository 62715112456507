<template>
  <VanPopup
    style="
      box-sizing: border-box;
      width: 80%;
      max-width: 350px;
      padding: 30px 20px;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    "
    round
    v-model="show"
    @click-overlay="closePop"
  >
    <div class="wrap">
      <div class="window">
        <!-- <div class="icon-wrapper" v-if="closeIcon">
          <svg class="icon" aria-hidden="true" @click.stop="closePop">
            <use xlink:href="#guanbixiao"></use>
          </svg>
        </div> -->
        <slot></slot>
      </div>
    </div>
  </VanPopup>
</template>

<script>
export default {
  name: '',
  /**
   * @closeIcon  // 是否显示关闭按钮
   */
  props: {
    closeIcon: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      show: true
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    handleConfirm () {
      this.$emit('close')
    }
  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.wrap {
  .window {
    box-sizing: border-box;
    border-radius: 10px;
    border-radius: 20px;
    text-align: center;
    color: $base-color;
    .icon-wrapper {
      display: flex;
      justify-content: flex-end;
      height: 25px !important;
      .icon {
        width: 24px;
      }
    }
    .title {
      text-align: center;
      font-weight: 600;
    }
  }
}
</style>
