<template>
  <VanPopup
    style="
      box-sizing: border-box;
      width: 93%;
      max-width: 350px;
      padding: 30px 20px;
      min-height: 90vh;
      background: #1e1e36;
    "
    round
    v-model="show"
    @click-overlay="closePop"
  >
    <div class="detail-wrap">
      <!-- 头部 -->
      <div class="header">
        <div class="title">{{ $t('dao.daoDetail') }}</div>
        <div class="info">
          <div class="logo">
            <img :src="dataSource.params[0]" alt="" />
            <div class="name" v-if="lang === 'ZH'">
              {{ dataSource.params[1] }}
            </div>
            <div class="name" v-else>{{ dataSource.params[2] }}</div>
          </div>
          <div class="content" v-if="lang === 'ZH'">
            {{ dataSource.params[3] }}
          </div>
          <div class="content" v-else>{{ dataSource.params[4] }}</div>
        </div>
        <div class="key-value">
          <div class="key">{{ $t('dao.membersNum') }}</div>
          <div class="value" translate="no">{{ dataSource.memberCount }}</div>
        </div>
      </div>
      <div class="line"></div>
      <!-- 代币信息  -->
      <div class="token-info">
        <div class="key-value">
          <div class="key">{{ $t('dao.contractAddress') }}</div>
          <div class="value" translate="no">
            {{ $gbUtils.formatAddress(dataSource.token, '...', 6) }}
          </div>
        </div>
        <div class="key-value">
          <div class="key">{{ $t('dao.tokenName') }}</div>
          <div class="value" translate="no">{{ tokenName || '-' }}</div>
        </div>
        <div class="key-value">
          <div class="key">{{ $t('dao.supply') }}</div>
          <div class="value" translate="no">{{ totalSupply || '-' }}</div>
        </div>
        <div class="key-value">
          <div class="key">{{ $t('dao.lpAddress') }}</div>
          <div class="value" translate="no">
            {{ $gbUtils.formatAddress(dataSource.lp, '...', 6) }}
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="councli-list">
        <div class="name">{{ $t('dao.councliList') }}</div>
        <div class="list" translate="no">
          <div class="item" v-for="item in councliList" :key="item.addr">
            {{ $gbUtils.formatAddress(item.addr, '...', 6) }}
          </div>
        </div>
      </div>
      <div class="line"></div>
      <!-- 底部 -->
      <div class="bottom">
        <div class="button">
          <div class="btn" @click="closePop">{{ $t('common.back') }}</div>
        </div>
        <div class="logo-wrap">
          <svg
            class="icon"
            aria-hidden="true"
            @click="handleOpenPage(dataSource.params[8])"
            v-if="!dataSource.params[8]"
          >
            <use xlink:href="#website1"></use>
          </svg>
          <img
            src="../../../assets/images/website.png"
            alt=""
            @click="handleOpenPage(dataSource.params[8])"
            v-else
          />
          <svg
            class="icon"
            aria-hidden="true"
            @click="handleOpenPage(dataSource.params[5])"
          >
            <use
              :xlink:href="
                dataSource.params[5] ? '#twitter1' : '#tuite_twitter431'
              "
            ></use>
          </svg>
          <svg
            class="icon"
            aria-hidden="true"
            @click="handleOpenPage(dataSource.params[6])"
          >
            <use
              :xlink:href="
                dataSource.params[6] ? '#telegram1' : '#shenqinghuibaobaobei1'
              "
            ></use>
          </svg>
          <svg
            class="icon"
            aria-hidden="true"
            @click="handleOpenPage(dataSource.params[7])"
          >
            <use
              :xlink:href="dataSource.params[7] ? '#discord4' : '#discord2'"
            ></use>
          </svg>
        </div>
      </div>
    </div>
  </VanPopup>
</template>

<script>
import ContractDao from '@/contracts/contractDao'
import ContractToken from '@/contracts/contractToken'
export default {
  name: '',
  props: ['dataSource', 'lang'],
  data () {
    return {
      show: true,
      tokenName: '',
      totalSupply: 0,
      councliList: []
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    async getTokenInfo () {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const nameRes = await ContractToken.getSymbol(this.dataSource.token)
      const supplyRes = await ContractToken.totalSupply(this.dataSource.token)
      if (nameRes.success && supplyRes.success) {
        this.tokenName = nameRes.result
        this.totalSupply = supplyRes.result
      }
      this.$emit('changeLoading', false)
    },
    async getList () {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const resp = await ContractDao.daoCouncliList(this.dataSource.daoAt)
      resp.result.forEach(item => {
        if (item.status) {
          this.councliList.push(item)
        }
      })
      console.log('this.councliList', this.councliList)
      this.$emit('changeLoading', false)
    },
    handleOpenPage (url) {
      if (!url) return
      window.open(url)
    }
  },
  mounted () {
    this.getTokenInfo()
    this.getList()
    console.log('this.dataSource', this.dataSource)
  }
}
</script>

<style scoped lang="scss">
.detail-wrap {
  color: $base-color;
  .line {
    height: 1px;
    background: $linear-bg3;
  }
  .header {
    padding-bottom: 20px;
    .title {
      color: $base-color;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }
    .info {
      margin: 40px 0 20px 0;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      .logo {
        height: 120px;
        margin-right: 20px;
        img {
          height: 85px;
          width: 85px;
          object-fit: contain;
        }
        .name {
          text-align: center;
          margin-top: 8px;
          font-size: 14px;
          font-weight: 600;
          color: $main-color1;
        }
      }
      .content {
        flex: 1;
        color: $base-color1;
        line-height: 1.3em;
      }
    }
  }
  .token-info {
    padding: 20px 0;
  }
  .key-value {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .key {
      color: $main-color1;
      text-align: left;
    }
    .value {
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      color: $base-color1;
    }
  }
  .councli-list {
    padding: 15px 0;
    .name {
      color: #888888;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .item {
        margin-top: 10px;
        width: 50%;
      }
    }
  }
  .bottom {
    margin-top: 40px;
    bottom: 0;
    .button {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      .btn {
        width: 134px;
        height: 39px;
        background: $linear-bg3;
        color: #fff;
        border-radius: 20px;
        line-height: 39px;
        text-align: center;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .logo-wrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 15px;
      img {
        width: 28px;
        height: 28px;
      }
      .icon {
        width: 28px;
        height: 28px;
      }
    }
  }
}
</style>
