<template>
  <div class="empty-page">
    <div class="box">
      <img src="../../assets/images/logo.png" class="img" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {}
  },
  methods: {},
  mounted () { }
}
</script>

<style scoped lang="scss">
.empty-page {
  // padding: 40px;
  .box {
    width: 100%;
    width: 165px;
    height: 165px;
    margin: 95px auto 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $linear-bg1;
      border-radius: 126px;
      opacity: 0.43;
      filter: blur(50px);
      z-index: 0;
    }
    .img {
      width: 100px;
      height: auto;
    }
    * {
      position: relative;
      z-index: 1;
    }
  }
}
</style>
